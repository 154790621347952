<template>
  <div class="report-general">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-body">
              <div class="table-header d-flex justify-content-between"></div>
              <div class="d-flex justify-content-between">
                <b-form-datepicker
                  id="start-datepicker"
                  v-model="created_after"
                  placeholder="De"
                  class="mb-2"
                ></b-form-datepicker>
                <b-form-datepicker
                  id="end-datepicker"
                  v-model="created_before"
                  class="mb-2"
                  placeholder="Até"
                ></b-form-datepicker>
              </div>
              <div
                class="form-group float-right d-flex justify-content-between"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-add button-filter"
                    v-on:click="providerData()"
                  >
                    Filtrar
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-primary btn-add"
                    v-on:click="generateFile()"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i> Gerar Arquivo
                  </button>
                </div>
              </div>

              <b-table
                hover
                no-local-sorting
                :fields="fields"
                :items="data"
                :per-page="0"
                :current-page="currentPage"
              >
                <template v-slot:cell(donation_transfer)="data">{{
                  formatMoney(data.value)
                }}</template>
                <template v-slot:cell(created_at)="data">{{
                  formatDate(data.value)
                }}</template>
                <template v-slot:cell(id)="data">
                  <button
                    class="btn btn-primary float-right btn-destroy-table"
                    v-on:click="destroy(data)"
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </template>
              </b-table>
              <div class="no-data" v-if="data.length == 0">
                <span>Sem dados</span>
              </div>
              <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "@/imports/Helpers";
import axios from "@/api/axios";
import moment from "moment";
import { bus } from "@/main";

export default {
  name: "report-general",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      rows: 0,
      data: [],
      created_after: "",
      created_before: "",
      wallet_id: "",
      filter: {
        name: "",
      },
      createParams: {
        wallets: [],
      },
      includes: ["phones"],
      sortBy: "",
      filterTerm: "",
      fields: [
        {
          key: "consumer_unit",
          label: "Unidade Consumidora",
          class: "consumer-cell",
        },
        { key: "name", label: "Nome", class: "name-cell" },
        {
          key: "donation_transfer",
          label: "Valor Repasse",
          class: "transfer-cell",
        },
      ],
      navigate_items: [
        {
          text: "Geral",
          active: true,
        },
      ],
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.providerData();
      },
    },
    created_after: {
      handler: function (value) {
        this.filterTable();
      },
    },
    created_before: {
      handler: function (value) {
        this.filterTable();
      },
    },
    wallet_id: {
      handler: function (value) {
        this.filterTable();
      },
    },
  },
  mounted() {
    this.getCreateParams();
    this.created_before = moment().format();
    this.created_after = moment().subtract(7, "days").format();
  },
  methods: {
    providerData() {
      helper
        .getPaginateQuery(
          "clients",
          helper.getUrlBuildQuery(
            this.perPage,
            this.currentPage,
            this.filter,
            this.includes,
            this.sortBy
          )
        )
        .then((response) => {
          this.rows = response.data.total;
          this.data = response.data.data;
        });
    },
    getCreateParams: function () {
      bus.$emit("changeLoading", true);
      helper.getCreateParams("clients").then((data) => {
        this.createParams = data.data;
        bus.$emit("changeLoading", false);
      });
    },
    generateFile: function () {
      axios
        .get(`${process.env.VUE_APP_API_HOST}api/export`, {
          params: {
            created_after: this.created_after,
            created_before: this.created_before,
            wallet_id: this.wallet_id,
            report_type: "general",
          },
          responseType: "blob",

          headers: {
            Accept: "application/vnd.ms-excel",
          },
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          let date = moment().format("DD-MM-YYYY");
          link.setAttribute(
            "download",
            "RelatorioGeral-" + date + ".xlsx"
          );
          document.body.appendChild(link);
          link.click();
        });
    },
    filterTable: function () {
      this.filter.created_after = this.created_after;
      this.filter.created_before = this.created_before;
      this.filter.wallet = this.wallet_id;
      this.currentPage = 1;
    },
    formatDate: function (date) {
      return helper.formatDateTime(date);
    },
    formatMoney: function (value) {
      return helper.formatMoney(value);
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/general.scss";
.report-general{
  .name-cell {
    width: 40%;
  }
  .button-filter{
    margin-right: 10px
  }
}
</style>